<template>
  <el-container class="home-container">

    <el-header>
      <!-- 图片和文字 -->
      <div>
        <img src="../assets/imgs/logo.png">
        <span><b>晨星眼镜管理平台</b></span>
      </div>
      <!-- 退出按钮 -->
      <el-button type="primary">退出</el-button>
    </el-header>

    <el-container>

      <el-aside width="160px">
        <el-menu router
            default-active="1"
            background-color="#506370"
            text-color="#fff"
            active-text-color="#fff">
          <el-submenu index="1" >
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>顾客管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/houseAdd">顾客添加</el-menu-item>
              <el-menu-item index="/codeAdd">码表添加</el-menu-item>
              <el-menu-item index="/houseList">顾客查询</el-menu-item>
              <el-menu-item index="/echarts">地图</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 主体区域 -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<style scoped>
  .home-container {
    height: 100%;
  }

  .el-header {
    background-color: #506370;
    display: flex; /* flex布局 */
    justify-content: space-between; /* 两个元素情况下，元素贴左右对齐 */
    align-items: center; /* 元素在垂直居中 */
    padding-left: 2px;
  }

  .el-header div {
    display: flex; /* flex布局 */
    justify-content: space-between; /* 两个元素情况下，元素贴左右对齐 */
    align-items: center; /* 元素在垂直居中 */
  }

  .el-header div img {
    margin-right: 10px;
  }

  .el-aside {
    background-color: #506370;
  }

  .el-menu {
    border-right: 0px; /* 解决菜单1像素宽度问题 */
  }
</style>
<script>
export default {
  name: 'Home',
}
</script>
