import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/houseAdd',
        name: 'HouseAdd',
        component: () => import('../views/house/Add')
      },
      {
        path: '/houseList',
        name: 'HouseList',
        component: () => import('../views/house/List')
      },
      {
        path: '/codeAdd',
        name: 'CodeAdd',
        component: () => import('../views/house/codeAdd')
      },
      {
        path: '/echarts',
        name: 'Echarts',
        component: () => import('../views/house/echarts')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
