import Vue from 'vue'
import App from './App.vue'
import router from './router'
import echarts from 'echarts'

// 导入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 导入全局CSS
import './assets/css/et.css'

// 导入axios，并将它设置为Vue实例的对象
import axios from 'axios'
axios.defaults.baseURL = 'http://47.105.60.102:80/house-app'
Vue.prototype.$http=axios
Vue.prototype.path = 'http://47.105.60.102:80/house-app'

Vue.config.productionTip = false

/* 1. 全局路由守卫 */
router.beforeEach((to, from, next) => {
  // 如果是从当前路由访问当前路由，直接返回
  if (from.path !== '/' && to.path === from.path) {
    console.log("to和from一致")
    return
  }

  // 如果访问/login，则直接跳转
  if(to.path === '/login') {
    return next()
  }

  // 如果不是访问/login, 则判断是否登录(也就是SessionStorage中是否有token)
  let token = sessionStorage.getItem('token');
  if (token) {
    return next()
  } else {
    return next('/login')
  }
})

/* Axios请求拦截器 */
axios.interceptors.request.use(config => {
  // 如果访问的登录接口, 直接放行
  if (config.url === '/user/login') {
    return config
  }

  let token = sessionStorage.getItem('token')
  config.headers.token = token
  return config;
}, error => {
  return Promise.reject('请求异常')
})

/* Axios的响应拦截器*/
axios.interceptors.response.use(response => {
  // 如果是登录接口的响应, 直接放行
  if (response.config.url === '/user/login') {
    return response
  }

  let code = response.data.code
  if (code === 999) {
    sessionStorage.clear()
    router.push('/login')
    return Promise.reject(response.data.msg)
  }

  // 设置新的token
  let token = response.headers.token
  console.log('token', token)
  if (token) {
    sessionStorage.setItem('token', token)
  }
  return response
}, error => {
  return Promise.reject(error)
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
